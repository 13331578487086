import { model, selectType, subscription } from "../../../costants/costants";
import { InputType } from "../../types/form/types";

export const installationFields: (t: any, subOpt: any[]) => InputType[] = (
  t: any,
  subOpt: any[]
) => [
  {
    key: subscription.key,
    name: subscription.name,
    label: t("selectFleet"),
    type: selectType,
    options: subOpt,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: model.key,
    name: model.name,
    label: t("activeBoxModel"),
    type: selectType,
    options: [{ lh: "AB(LH)-CR82475800" }, { rh: "AB(RH)-CR82475700" }],
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: "activeBoxSn",
    name: "activeBoxSn",
    label: t("activeBoxSn"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: "mateModel",
    name: "mateModel",
    label: t("mateModel"),
    type: selectType,
    options: [
      { "CR82477700 MATE-XT 4.0 S-RH": "CR82477700 MATE-XT 4.0 S-RH" },
      { "CR82477500 MATE-XT 4.0 L-RH": "CR82477500 MATE-XT 4.0 L-RH" },
      { "CR82477800 MATE-XT 4.0 S-LH": "CR82477800 MATE-XT 4.0 S-LH" },
      { "CR82477600 MATE-XT 4.0 L-LH": "CR82477600 MATE-XT 4.0 L-LH" },
      {
        "CR82492400 MATE-XT 4.0 L-LH Wuerth":
          "CR82492400 MATE-XT 4.0 L-LH Wuerth",
      },
    ],
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: "mateSn",
    name: "mateSn",
    label: t("mateSn"),
  },
  {
    key: "customer",
    name: "customer",
    label: t("customer"),
  },
];
